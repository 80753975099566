import { fieldSet } from 'simple-redux-field';

import { patchUserProfileApi } from './userProfile.api';
import { FIELD__USER_PROFILE__UPDATE, NAMESPACE } from './userProfile.constants';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

export const updateUserProfile = (id, data) => dispatch => {
  dispatch(fieldSet(FIELD__USER_PROFILE__UPDATE, {
    inProgress: true,
    error: null,
  }, NAMESPACE));

  return patchUserProfileApi(id, data)
    .then(response => {
      dispatch(addMarketplaceEntities(response))
      dispatch(fieldSet(FIELD__USER_PROFILE__UPDATE, {
        inProgress: false,
      }, NAMESPACE));
      return response;
    })
    .catch(e => {
      dispatch(fieldSet(FIELD__USER_PROFILE__UPDATE, {
        inProgress: false,
        error: e.message,
      }, NAMESPACE));
      throw e;
    })
  ;
};
