import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import omit from 'lodash/omit';
import classNames from 'classnames';

import config from '../../../config';
import { injectIntl, intlShape, FormattedMessage } from '../../../util/reactIntl';
import routeConfiguration from '../../../routing/routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../../util/routes';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  isOriginInUse,
} from '../../../util/search';
import { parse, stringify } from '../../../util/urlHelpers';
import { propTypes } from '../../../util/types';
import { getListingsById } from '../../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../../ducks/UI.duck';
import {
  searchMapListings,
  setActiveListing,
  setSearchValues,
  $searchValues,
  $searchMetadata,
} from '../SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
  rangeValueToSearchParam,
  pageMetaTags,
  isEmptySearchMetadata,
} from '../SearchPage.helpers';
import {
  IS_SHOW_MAP_LOCAL_STORAGE_KEY,
  FILTER_KEYWORD,
  REAL_ESTATE_CUSTOM_FILTER_KEYS,
  FILTER_KEY_HOME_TYPE,
} from '../SearchPage.constants';
import {
  $airplanesCategoriesField,
  $airplanesMetadataField,
} from '../../../modules/airplanes/airplanes.selectors';
import { addSearch, setSaveSearchInit } from '../../../modules/saveSearch/saveSearch.creators';
import { $saveSearchField } from '../../../modules/saveSearch/saveSearch.selectors';
import { greatGoodDealsService } from '../../../services/services';

import SearchMap from '../SearchMap/SearchMap';
import MainPanelHeader from '../AircraftSearchPage/MainPanelHeader/MainPanelHeader';
import SearchFiltersSecondary from '../SearchFiltersSecondary/SearchFiltersSecondary';
import SearchFiltersPrimary from '../SearchFiltersPrimary/SearchFiltersPrimary';
import SearchFiltersMobile from '../SearchFiltersMobile/SearchFiltersMobile';
import SortBy from '../SortBy/SortBy';
import SearchResultsPanel from '../SearchResultsPanel/SearchResultsPanel';
import { Footer, ModalInMobile, Page } from '../../../components';
import TopbarContainer from '../../../containers/TopbarContainer/TopbarContainer';
import FilterComponent from '../FilterComponent';
import SaveSearchModal from '../../../components/SaveSearchModal/SaveSearchModal';

import css from '../SearchPage.module.scss';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

const validUrlQueryParamsFromProps = props => {
  const { location, filterConfig } = props;
  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  return validURLParamsForExtendedData(searchInURL, filterConfig);
};

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.resultPanelRef = React.createRef();

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isSecondaryFiltersOpen: false,
      isShowMap: false,
      shouldClearQuickSearch: false,
      isSaveSearchModalOpen: false,
      mobileSearchParams: {},
      scrolledToFooter: true,
    };

    this.searchMapListingsInProgress = false;
    this.mapSearchBounds = null;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);
    this.onChangeMobileFilters = this.onChangeMobileFilters.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  handleScroll = () => {
    const scrolled = window.scrollY;
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight;

    if (Math.round(scrolled) + windowHeight >= fullHeight - 73) {
      this.setState({scrolledToFooter: true})
    } else {
      this.setState({scrolledToFooter: false})
    }
  };

  componentDidMount() {
    const storageIsShowMap = window.localStorage.getItem(IS_SHOW_MAP_LOCAL_STORAGE_KEY);
    if (storageIsShowMap !== null) {
      this.setState({
        isShowMap: JSON.parse(storageIsShowMap),
      });
    }
    if (this.props.location) this.setState({ mobileSearchParams: parse(this.props.location.search) })

    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (isEmptySearchMetadata(prevProps.searchMetadata) && !isEmptySearchMetadata(this.props.searchMetadata)) {
      const metadata = this.props.searchMetadata;
      if (window.olytics) {
        window.olytics.fire({
          behaviorId: config.olyticsBehaviorId,
          ...(metadata.category && { category: metadata.category.label }),
          ...(metadata.maker && { make: metadata.maker.label }),
          ...(metadata.modelFamily && { modelfamily: metadata.modelFamily.label }),
          ...(metadata.model && { model: metadata.model.label }),
        });
      }
    }

    if (prevProps.searchInProgress && !this.props.searchInProgress) {
      const resultPanelHeight = this.resultPanelRef.current.clientHeight;
      const documentHeight = document.documentElement.clientHeight;
      this.setState({
        scrolledToFooter: resultPanelHeight + 300 < documentHeight,
      });
    }
  }

  isSaveSearchModalOpenSet = value => {
    this.setState({
      isSaveSearchModalOpen: value,
    });
    if (!value) {
      this.props.onSetSaveSearchInit();
    }
  };

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName(
      'RealEstateSearchPage',
      routes
    );
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = isOriginInUse(config) ? { origin: viewportCenter } : {};
      this.mapSearchBounds = viewportBounds;

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...rest,
        // ...validFilterParams(rest, filterConfig),
      };
      history.push(createResourceLocatorString(
        'RealEstateSearchPage',
        routes,
        {},
        searchParams
      ));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, sortConfig, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
    // SECONDARY_RANGE_FILTER_KEYS.forEach(key => {
    //   if (key in search) {
    //     delete (search[key]);
    //   }
    // });

    // const customSecondaryRangeParams = SECONDARY_RANGE_FILTER_KEYS.map(key => {
    //   return { key, val: rangeValueToSearchParam(this.props.searchValues[key]) };
    // });
    // const customSecondaryRangeSearchParams = this.prepareSearchParams(customSecondaryRangeParams);
    // Object.keys(customSecondaryRangeSearchParams).forEach(key => {
    //   if (!SECONDARY_RANGE_FILTER_KEYS.includes(key)) {
    //     delete (customSecondaryRangeSearchParams[key]);
    //   }
    // });

    const allSearchParams = {
      ...search,
      // ...customSecondaryRangeSearchParams,
    };

    history.push(createResourceLocatorString(
      'RealEstateSearchPage',
      routeConfiguration(),
      {},
      allSearchParams
    ));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);
    filterQueryParamNames.push([
      ...REAL_ESTATE_CUSTOM_FILTER_KEYS,
      'bounds',
      'address',
    ]);

    // Reset state
    this.setState({ currentQueryParams: {} });
    this.resetAllSearchFilters();

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames.flat());
    history.push(createResourceLocatorString(
      'RealEstateSearchPage',
      routeConfiguration(),
      {},
      queryParams
    ));
  }

  handleSaveSearchSubmit = values => {
    this.props.onAddSearch(values.name, values.freq)
      .then(() => this.isSaveSearchModalOpenSet(false))
      .catch(() => {})
    ;
  };

  onChangeMobileFilters = values => {
    this.setState({ mobileSearchParams: { ...this.state.mobileSearchParams, ...values } })
  }

  onSubmitAllMobileFilters = () => {
    const { history } = this.props;
    history.push(createResourceLocatorString(
      'RealEstateSearchPage',
      routeConfiguration(),
      {},
      this.state.mobileSearchParams
    ));
  }

  handleSaveSearchClick = () => {
    if (this.props.isAuthenticated) {
      this.isSaveSearchModalOpenSet(true);
    } else {
      this.props.history.push(
        pathByRouteName('LoginPage', routeConfiguration()),
        { from: `${location.pathname}${location.search}${location.hash}` }
      );
    }
  };

  initialValues(queryParamNames, isLiveEdit) {
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState && !isLiveEdit ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
        return { ...acc, [paramName]: getInitialValue(paramName) };
      }, {})
      : {}
    ;
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, sortConfig, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, pub_keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { pub_keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
          history.push(createResourceLocatorString(
            'RealEstateSearchPage',
            routeConfiguration(),
            {},
            search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString(
      'RealEstateSearchPage',
      routeConfiguration(),
      {},
      queryParams
    ));
  }

  handleShowMapChange = value => {
    const { history } = this.props;
    window.localStorage.setItem(IS_SHOW_MAP_LOCAL_STORAGE_KEY, value);
    this.setState({
      isShowMap: value,
    });

    const routes = routeConfiguration();
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    if (!value && (urlQueryParams.mapSearch || urlQueryParams.bounds || urlQueryParams.address)) {
      history.push(createResourceLocatorString(
        'RealEstateSearchPage',
        routes,
        {},
        {
          ...omit(urlQueryParams, ['mapSearch', 'bounds', 'address'])
        }
      ));
    } else if (this.mapSearchBounds) {
      const searchParams = {
        bounds: this.mapSearchBounds,
        mapSearch: true,
        ...urlQueryParams,
      };

      history.push(createResourceLocatorString(
        'RealEstateSearchPage',
        routes,
        {},
        searchParams
      ));
    }
  };

  prepareSearchParams = (values) => {
    const searchParams = new URLSearchParams(location.search);
    values.forEach(i => {
      const key = i.key;
      const val = i.val;
      if (val) {
        searchParams.append(key, val);
      } else {
        searchParams.delete(key);
      }
    });
    if (searchParams.has('page')) {
      searchParams.delete('page');
    }

    return Object.fromEntries(searchParams);
  };

  searchRequest = queryParams => {
    this.props.history.push(createResourceLocatorString(
      'RealEstateSearchPage',
      routeConfiguration(),
      {},
      queryParams
    ));
  };

  resetAllSearchFilters = () => {
    const searchValues = REAL_ESTATE_CUSTOM_FILTER_KEYS.reduce((acc, curr) => ({
      ...acc,
      [curr]: null,
    }), {});
    this.props.onSetSearchValues(searchValues);
    this.setState({
      shouldClearQuickSearch: true,
    });
  };
  //
  // handleMakeModelSearchSubmit = values => {
  //   this.props.onSetSearchValues(values);
  //   const searchParams = this.prepareSearchParams([
  //     { key: FILTER_KEY_MAKER, val: get(values, `${FILTER_KEY_MAKER}.value`, null) },
  //     { key: FILTER_KEY_MODEL_FAMILY, val: get(values, `${FILTER_KEY_MODEL_FAMILY}.value`, null) },
  //     { key: FILTER_KEY_MODEL, val: get(values, `${FILTER_KEY_MODEL}.value`, null) },
  //   ]);
  //
  //   this.searchRequest(searchParams);
  // };

  handleHomeTypeSearchSubmit = values => {
    this.props.onSetSearchValues(values);
    const searchParams = this.prepareSearchParams([
      { key: FILTER_KEY_HOME_TYPE, val: values[FILTER_KEY_HOME_TYPE] },
    ]);
    this.searchRequest(searchParams);
  };

  // handleEngineHoursSearchSubmit = values => {
  //   this.props.onSetSearchValues(values);
  //   const params = FILTER_KEYS_ENGINE_HOURS.map(key => {
  //     return { key, val: rangeValueToSearchParam(values[key]) };
  //   });
  //   const searchParams = this.prepareSearchParams(params);
  //   this.searchRequest(searchParams);
  // };

  handleRangeFieldSubmit = values => {
    this.props.onSetSearchValues(values);
    const params = Object.keys(values || {}).map(key => {
      return { key, val: rangeValueToSearchParam(values[key]) };
    });
    const searchParams = this.prepareSearchParams(params);
    this.searchRequest(searchParams);
  };

  onClosePanelSecondaryFilters= () => {
    this.setState({ isSecondaryFiltersOpen: false })
    this.setState({scrolledToFooter: false})
  }

  render() {
    const {
      dealCounts,
      airplanesMetadata,
      searchValues,
      intl,
      listings,
      filterConfig,
      filterConfigMobile,
      sortConfig,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      onSetSearchValues,
      currentUser,
      saveSearchField,
      currentUserInProgress,
      searchMetadata,
    } = this.props;

    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      (!isMobileLayout && this.state.isShowMap) || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const availableFilters = isKeywordSearch
      ? filterConfig.filter(f => f.type !== FILTER_KEYWORD)
      : filterConfig;

    const mobileFilters = isKeywordSearch
      ? filterConfigMobile.filter(f => f.type !== FILTER_KEYWORD)
      : filterConfigMobile;

    const primaryFilters = availableFilters.filter(f => f.group === 'primary');
    const secondaryFilters = availableFilters.filter(f => f.group !== 'primary');
    const hasSecondaryFilters = !!(secondaryFilters && secondaryFilters.length > 0);

    // Selected aka active filters
    const selectedFilters = validFilterParams(validQueryParams, filterConfig);
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'pub_keywords').length
      : keysOfSelectedFilters.length;

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(validQueryParams, secondaryFilters)
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
        isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
        toggleSecondaryFiltersOpen: isOpen => {
          this.setState({ isSecondaryFiltersOpen: isOpen, currentQueryParams: {} });
          this.setState(prevStates => ({scrolledToFooter: !prevStates.scrolledToFooter}))
        },
        selectedSecondaryFiltersCount,
      }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;

    const sortBy = mode => {
      const conflictingFilterActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        validQueryParams,
        filterConfig
      );

      const mobileClassesMaybe =
        mode === 'mobile'
          ? {
            rootClassName: css.sortBy,
            menuLabelRootClassName: css.sortByMenuLabel,
          }
          : { className: css.sortByDesktop };
      return sortConfig.active ? (
        <SortBy
          {...mobileClassesMaybe}
          sortConfig={sortConfig}
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={sortConfig.conflictingFilters?.length > 0}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
          isMobileLayout={isMobileLayout}
        />
      ) : null;
    };

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchInURL || {},
      intl
    );

    const hasNoResult = listingsAreLoaded && totalItems === 0;
    const hasSearchParams = location.search?.length > 0;
    const noResultsInfo = hasNoResult ? (
      <div className={css.noSearchResults}>
        <FormattedMessage id="SearchPage.noResults" />
        <br />
        {hasSearchParams ? (
          <button className={css.resetAllFiltersButton} onClick={e => this.resetAll(e)}>
            <FormattedMessage id={'SearchPage.resetAllFilters'} />
          </button>
        ) : null}
      </div>
    ) : null;

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    const {
      pageTitle = title,
      pageDescription = description,
    } = pageMetaTags(searchMetadata);

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={pageDescription}
        title={pageTitle}
        schema={schema}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="RealEstateSearchPage"
          currentSearchParams={urlQueryParams}
          shouldClearQuickSearch={this.state.shouldClearQuickSearch}
        />
        <div className={css.container}>
          <div className={css.searchResultContainer}>
            {/* <ListingTypeButtons
              history={history}
              listingType={params.listingType}
            /> */}
            <h1 className={css.titlePage}>
              {intl.formatMessage({ id: 'RealEstateSearchPage.title' })}
            </h1>
            <SearchFiltersMobile
              pageName='RealEstateSearchPage'
              className={css.searchFiltersMobileMap}
              urlQueryParams={validQueryParams}
              sortByComponent={sortBy('mobile')}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              onMapIconClick={onMapIconClick}
              onManageDisableScrolling={onManageDisableScrolling}
              onOpenModal={this.onOpenMobileModal}
              onCloseModal={this.onCloseMobileModal}
              resetAll={this.resetAll}
              selectedFiltersCount={selectedFiltersCountForMobile}
              noResultsInfo={noResultsInfo}
              isMapVariant
              onSubmitAllMobileFilters={this.onSubmitAllMobileFilters}
              onSaveSearchClick={this.handleSaveSearchClick}
            >
              {mobileFilters.map(config => {
                return (
                  <FilterComponent
                    key={`SearchFiltersMobile.${config.id}`}
                    dealCounts={dealCounts}
                    showPriceSlider={false}
                    isMobile={isMobileLayout}
                    intl={intl}
                    idPrefix="SearchFiltersMobile"
                    filterConfig={config}
                    urlQueryParams={validQueryParams}
                    initialValues={this.initialValues}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    liveEdit
                    showAsPopup={false}
                    searchValues={searchValues}
                    onSetSearchValues={onSetSearchValues}
                    onHomeTypeSearchSubmit={this.handleHomeTypeSearchSubmit}
                    onRangeFieldSubmit={this.handleRangeFieldSubmit}
                    onChangeMobileFilters={this.onChangeMobileFilters}
                    mobileSearchParams={this.state.mobileSearchParams}
                  />
                );
              })}
            </SearchFiltersMobile>
            <MainPanelHeader
              className={css.mainPanelMapVariant}
              isShowMap={this.state.isShowMap}
              sortByComponent={sortBy('desktop')}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              pagination={pagination}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              noResultsInfo={noResultsInfo}
              onShowMapChange={this.handleShowMapChange}
            >
              <SearchFiltersPrimary
                showMoreFilters={false}
                hasSearchParams={hasSearchParams}
                resetAll={this.resetAll}
                onSaveSearchClick={this.handleSaveSearchClick}
                {...propsForSecondaryFiltersToggle}
              >
                {primaryFilters.map(config => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersPrimary.${config.id}`}
                      dealCounts={dealCounts}
                      showPriceSlider={false}
                      isMobile={isMobileLayout}
                      intl={intl}
                      idPrefix="SearchFiltersPrimary"
                      filterConfig={config}
                      urlQueryParams={validQueryParams}
                      initialValues={this.initialValues}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      showAsPopup
                      liveEdit={false}
                      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                      searchValues={searchValues}
                      onSetSearchValues={onSetSearchValues}
                      onHomeTypeSearchSubmit={this.handleHomeTypeSearchSubmit}
                      onRangeFieldSubmit={this.handleRangeFieldSubmit}
                      onChangeMobileFilters={this.onChangeMobileFilters}
                      mobileSearchParams={this.state.mobileSearchParams}
                    />
                  );
                })}
              </SearchFiltersPrimary>
            </MainPanelHeader>
            {isSecondaryFiltersOpen ? (
              <div className={classNames(css.searchFiltersPanel)}>
                <SearchFiltersSecondary
                  urlQueryParams={validQueryParams}
                  listingsAreLoaded={listingsAreLoaded}
                  applyFilters={this.applyFilters}
                  cancelFilters={this.cancelFilters}
                  resetAll={this.resetAll}
                  onClosePanel={this.onClosePanelSecondaryFilters}
                  isShowMap={this.state.isShowMap}
                >
                  {secondaryFilters.map(config => {
                    return (
                      <FilterComponent
                        key={`SearchFiltersSecondary.${config.id}`}
                        dealCounts={dealCounts}
                        showPriceSlider={false}
                        isMobile={isMobileLayout}
                        intl={intl}
                        idPrefix="SearchFiltersSecondary"
                        filterConfig={config}
                        urlQueryParams={validQueryParams}
                        initialValues={this.initialValues}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        showAsPopup={false}
                        liveEdit={false}
                        searchValues={searchValues}
                        onSetSearchValues={onSetSearchValues}
                        onHomeTypeSearchSubmit={this.handleHomeTypeSearchSubmit}
                        onRangeFieldSubmit={this.handleRangeFieldSubmit}
                        mobileSearchParams={this.state.mobileSearchParams}
                        onChangeMobileFilters={this.onChangeMobileFilters}
                      />
                    );
                  })}
                </SearchFiltersSecondary>
              </div>
            ) : (
              <div
                ref={this.resultPanelRef}
                className={classNames(css.listingsForMapVariant, {
                  [css.newSearchInProgress]: !listingsAreLoaded,
                })}
              >
                {searchListingsError ? (
                  <h2 className={css.error}>
                    <FormattedMessage id="SearchPage.searchError" />
                  </h2>
                ) : null}
                <SearchResultsPanel
                  pageName='RealEstateSearchPage'
                  airplanesMetadata={airplanesMetadata}
                  className={css.searchListingsPanel}
                  listings={listings}
                  pagination={listingsAreLoaded ? pagination : null}
                  search={parse(location.search)}
                  setActiveListing={onActivateListing}
                  currentUser={currentUser}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isMapVariant
                />
              </div>
            )}
          </div>
          {shouldShowSearchMap && (
            <ModalInMobile
              className={css.mapPanel}
              id="SearchPage.map"
              isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
              onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.mapWrapper}>
                {shouldShowSearchMap && (
                  <SearchMap
                    airplanesMetadata={airplanesMetadata}
                    intl={intl}
                    reusableContainerClassName={css.map}
                    rootClassName={classNames({[css.mapFooter]: this.state.scrolledToFooter})}
                    scrolledToFooter={this.state.scrolledToFooter}
                    activeListingId={activeListingId}
                    bounds={bounds}
                    center={origin}
                    isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                    location={location}
                    listings={mapListings || []}
                    onMapMoveEnd={this.onMapMoveEnd}
                    onCloseAsModal={() => {
                      onManageDisableScrolling('SearchPage.map', false);
                    }}
                    messages={intl.messages}
                  />
                )}
              </div>
            </ModalInMobile>
          )}
          <SaveSearchModal
            currentUserInProgress={currentUserInProgress}
            saveSearchField={saveSearchField}
            isMobileLayout={isMobileLayout}
            isOpen={this.state.isSaveSearchModalOpen}
            intl={intl}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={onManageDisableScrolling}
            onClose={() => this.isSaveSearchModalOpenSet(false)}
            onSubmit={this.handleSaveSearchSubmit}
          />
        </div>
        <Footer />
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.realEstateFilters,
  filterConfigMobile: config.custom.realEstateFiltersMobile,
  sortConfig: config.custom.realEstateSortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserInProgress } = state.user;
  const { isAuthenticated } = state.Auth;

  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    currentUser,
    currentUserInProgress,
    isAuthenticated,
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    searchValues: $searchValues(state),
    airplanesCategoriesField: $airplanesCategoriesField(state),
    airplanesMetadata: $airplanesMetadataField(state),
    saveSearchField: $saveSearchField(state),
    searchMetadata: $searchMetadata(state),
    dealCounts: greatGoodDealsService.$getAllDealsCount(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onSetSearchValues: values => dispatch(setSearchValues(values)),
  onAddSearch: (name, freq) => dispatch(addSearch(name, freq)),
  onSetSaveSearchInit: () => dispatch(setSaveSearchInit()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
