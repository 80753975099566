import { $fields } from 'simple-redux-field';

import {
  FIELD__SAVE_SEARCH__REMOVE,
  FIELD__SAVE_SEARCH__SAVE,
  INITIAL_REMOVE,
  INITIAL_SAVE,
} from './saveSearch.constants';

export const $saveSearchField = state => {
  return $fields(state)[FIELD__SAVE_SEARCH__SAVE]
    || INITIAL_SAVE
  ;
};

export const $removeSearchField = state => {
  return $fields(state)[FIELD__SAVE_SEARCH__REMOVE]
    || INITIAL_REMOVE
  ;
};
