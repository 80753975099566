import { fieldSet } from 'simple-redux-field';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import objectHash from 'object-hash';

import { priceSearchParams } from '../../util/currency';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { updateUserProfile } from '../userProfile/userProfile.creators';
import {
  FIELD__SAVE_SEARCH__REMOVE,
  FIELD__SAVE_SEARCH__SAVE,
  INITIAL_SAVE,
} from './saveSearch.constants';

const getStateSearches = state => {
  return get(state, 'user.currentUser.attributes.profile.privateData.searches', []);
}

const getStateSavedSearchDailyNotifications = state => {
  return get(state, 'user.currentUser.attributes.profile.metadata.savedSearchDailyNotifications', []);
};

const hasSearch = (searches, searchObj) => {
  return searches.some(item => isEqual(item.searchObj, searchObj));
};

export const setSaveSearchInit = () => dispatch => {
  dispatch(fieldSet(FIELD__SAVE_SEARCH__SAVE, INITIAL_SAVE));
};

const saveSearch = (data) => (dispatch, getState, sdk) => {
  return sdk.currentUser.updateProfile(data)
    .then(response => {
      dispatch(fetchCurrentUser());
      dispatch(fieldSet(FIELD__SAVE_SEARCH__SAVE, {
        inProgress: false,
      }));
      return response;
    })
    .catch(e => {
      dispatch(fieldSet(FIELD__SAVE_SEARCH__SAVE, {
        inProgress: false,
        error: e.message,
      }));
      throw e;
    })
  ;
};

export const addSearch = (name, freq) => (dispatch, getState) => {
  dispatch(fieldSet(FIELD__SAVE_SEARCH__SAVE, {
    inProgress: true,
    error: null,
  }));
  const state = getState();
  const search = window.location.search;
  const searchObj = Object.fromEntries(new URLSearchParams(search));
  const stateSearches = getStateSearches(state);

  if (hasSearch(stateSearches, searchObj)) {
    dispatch(fieldSet(FIELD__SAVE_SEARCH__SAVE, {
      inProgress: false,
      error: 'You already have the same search with another name.',
    }));
    return Promise.reject();
  }
  const newSearches = [
    ...stateSearches,
    {
      name,
      freq,
      searchObj,
      hash: objectHash.sha1(searchObj),
    },
  ];
  const newSearchNotificationFrequencies = uniq(newSearches.map(i => i.freq));

  const data = {
    privateData: {
      searches: newSearches,
      searchNotificationFrequencies: newSearchNotificationFrequencies,
    },
  };

  return dispatch(saveSearch(data));
};

export const editSearch = item => (dispatch, getState) => {
  dispatch(fieldSet(FIELD__SAVE_SEARCH__SAVE, {
    inProgress: true,
    error: null,
  }));
  const state = getState();
  const stateSearches = getStateSearches(state);
  const newSearches = stateSearches.map(i => {
    if (i.hash === item.hash) {
      return {
        name: item.name,
        freq: item.freq,
        searchObj: item.searchObj,
        hash: objectHash.sha1(item.searchObj),
      };
    }
    return i;
  });
  const newSearchNotificationFrequencies = uniq(newSearches.map(i => i.freq));
  const data = {
    privateData: {
      searches: newSearches,
      searchNotificationFrequencies: newSearchNotificationFrequencies,
    },
  };

  return dispatch(saveSearch(data));
};

export const removeSearch = item => (dispatch, getState, sdk) => {
  dispatch(fieldSet(FIELD__SAVE_SEARCH__REMOVE, {
    removingItem: item,
    inProgress: true,
    error: null,
  }));
  const state = getState();
  const stateSearches = getStateSearches(state);
  const newSearches = stateSearches.filter(i => i.hash !== item.hash);
  const newSearchNotificationFrequencies = uniq(newSearches.map(i => i.freq));
  const data = {
    privateData: {
      searches: !!newSearches.length ? newSearches : null,
      searchNotificationFrequencies: !!newSearchNotificationFrequencies.length
        ? newSearchNotificationFrequencies
        : null,
    },
  };

  return sdk.currentUser.updateProfile(data)
    .then(response => {
      dispatch(fetchCurrentUser());
      return response;
    })
    .then(async updatePrivateDataResponse => {
      const stateNotifications = getStateSavedSearchDailyNotifications(state);
      const newNotifications = stateNotifications.filter(i => i.searchHash !== item.hash);
      const currUserId = state.user.currentUser.id.uuid;
      dispatch(updateUserProfile(currUserId, {
        metadata: {
          savedSearchDailyNotifications: !!newNotifications.length ? newNotifications : null,
          needSendDailyNotifications: !!newNotifications.length ? true : null,
        },
      }));
      return updatePrivateDataResponse;
    })
    .then(updatePrivateDataResponse => {
      dispatch(fieldSet(FIELD__SAVE_SEARCH__REMOVE, {
        removingItem: null,
        inProgress: false,
      }));
      return updatePrivateDataResponse;
    })
    .catch(e => {
      dispatch(fieldSet(FIELD__SAVE_SEARCH__REMOVE, {
        removingItem: null,
        inProgress: false,
        error: e.message,
      }));
      throw e;
    })
  ;
};

export const getSearchCount = searchObj => (dispatch, getState, sdk) => {
  return sdk.listings.query({
    ...{
      ...searchObj,
      ...(searchObj.price && priceSearchParams(searchObj.price)),
    },
    'fields.listing': ['id'],
  })
    .then(response => {
      return response.data.meta.totalItems;
    })
    .catch(e => {
      throw e;
    })
  ;
};
