export const NAMESPACE = 'SAVE_SEARCH';

export const FIELD__SAVE_SEARCH__SAVE = `FIELD::${NAMESPACE}::SAVE`;

export const FIELD__SAVE_SEARCH__REMOVE = `FIELD::${NAMESPACE}::REMOVE';`

export const INITIAL_SAVE = {
  inProgress: false,
  error: null,
};

export const INITIAL_REMOVE = {
  removingItem: null,
  inProgress: false,
  error: null,
};
